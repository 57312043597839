import { Injectable } from '@angular/core';
import { URL_SERVICIOS } from '../../config/config';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Usuario } from 'src/app/models/usuario.model';

@Injectable()
export class UsuarioService {

  constructor(
    public http: HttpClient
  ) { }

  crearUsuarios(usuario: Usuario) {
    let url = `${URL_SERVICIOS}/api/usuarios`;
    return this.http.post<any>(url, usuario);
  }

  actualizarUsuarios(usuario: Usuario) {
    let url = `${URL_SERVICIOS}/api/usuarios/${usuario.idUsuario}`;
    return this.http.put<any>(url, usuario);
  }

  obtenerUsuarios(idEmppresa: number) {
    let url = `${URL_SERVICIOS}/api/usuarios/${idEmppresa}`;
    return this.http.get<any>(url);
  }


  desactivarUsuarios(usuario: Usuario) {
    let url = `${URL_SERVICIOS}/api/usuarios/activos/${usuario.idUsuario}`;
    return this.http.put<any>(url, usuario);
  }

  crearUsuariosEmpresa(usuario: Usuario) {
    let url = `${URL_SERVICIOS}/api/usuarios/empresa`;
    return this.http.post<any>(url, usuario);

  }

  actualizarUsuariosEmpresa(usuario: Usuario) {
    let url = `${URL_SERVICIOS}/api/usuarios/empresa/${usuario.idUsuario}`;
    return this.http.put<any>(url, usuario);
  }

  obtenerUsuariosEmpresa(params: HttpParams) {
    let url = `${URL_SERVICIOS}/api/usuarios/empresa`;
    return this.http.get<any>(url);
  }


  desactivarUsuariosEmpresa(usuario: Usuario) {
    let url = `${URL_SERVICIOS}/api/usuarios/empresa/activos/${usuario.idUsuario}`;
    return this.http.put<any>(url, usuario);
  }



  
}