export const VERSION = 1.0;
/*Ambientes de pruebas*/
// export const URL_SERVICIOS = 'http://200.66.74.166:8079';

/*Ambientes de produccion*/
export const URL_SERVICIOS = 'https://www.administracion-360.com/norma35';


/*Ambiente local*/
// export const URL_SERVICIOS = 'http://localhost:8080'



