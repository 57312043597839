import { Injectable } from '@angular/core';
import { URL_SERVICIOS } from '../../config/config';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class MenuService {

  constructor(
    public http: HttpClient
  ) { }

  obtenerMenusPorRol(idRol: number) {
    let url = `${URL_SERVICIOS}/api/menus/${idRol}`;
    return this.http.get<any>(url);
  }



}