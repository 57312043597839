<header class="topbar">
    <nav class="navbar top-navbar navbar-expand-md navbar-light">
        <div class="navbar-header">
            <a class="navbar-brand" href="index.html">
                <b>
                  <img src="../assets/A360_IDENTIDAD FINAL-02.png" alt="homepage" class="light-logo" />
              </b>
            </a>
        </div>
        <div class="navbar-collapse">
            <ul class="navbar-nav mr-auto">
                <li class="nav-item"> <a class="nav-link nav-toggler hidden-md-up waves-effect waves-dark" href="javascript:void(0)"><i class="ti-menu"></i></a> </li>
                <li class="nav-item"> <a class="nav-link sidebartoggler hidden-sm-down waves-effect waves-dark" href="javascript:void(0)"><i class="ti-menu"></i></a> </li>
                <li class="nav-item hidden-sm-down"></li>
            </ul>
            <ul class="navbar-nav my-lg-0">
                <li class="nav-item dropdown  m-t-10" *ngIf="this.rol.nombre !== 'ROLE_SUPER_ADMIN' ">
                    <div class="nav-link dropdown-toggle waves-effect waves-dark btn-scs" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <a class="get-code" data-toggle="collapse" aria-expanded="true"> <label title="{{sucursal.nombre}}" data-toggle="tooltip" (click)="obtenerSucursales()" style="color: beige; cursor: pointer"><strong class="hidden-sm-down">Sucursal: </strong>{{nombreSucursalR}}</label>
                        </a>
                    </div>

                    <div class="dropdown-menu dropdown-menu-right mailbox animated bounceInDown" style="cursor: pointer;">
                        <ul>
                            <li>
                                <div class="drop-title text-center">Sucursales</div>
                            </li>
                            <li>
                                <div class="message-center">
                                    <a *ngFor="let sucursal of sucursales" class="text-center" (click)="obtenerSucursal(sucursal)">
                                        <div class="btn btn-info btn-circle "><i class="fas fa-building"></i></div>
                                        <div class="mail-contnet ">
                                            <h5> <strong>{{sucursal.nombre}}</strong> </h5>
                                        </div>
                                    </a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle waves-effect waves-dark" href="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img src="../../../assets/img/no-usuario.png" alt="user" class="profile-pic" /></a>
                    <div class="dropdown-menu dropdown-menu-right animated flipInY">
                        <ul class="dropdown-user">
                            <li>
                                <div class="dw-user-box">
                                    <div class="u-img text-center"><img src="../../../assets/img/no-usuario.png" alt="user"></div>
                                    <div class="u-text">
                                        <h4><strong>
                                        </strong> {{usuario.nombre}} {{usuario.apePaterno}}</h4>
                                        <p class="text-muted">{{usuario.usuario}}</p>
                                    </div>
                                </div>
                            </li>
                            <li role="separator" class="divider"></li>
                            <li *ngIf="rol.nombre == 'ROLE_COORDINADOR'"><a (click)="cambiarEmpresa()" class="pointer"><i class="far fa-building"></i> <strong>Cambiar de Empresa</strong> </a></li>
                            <li role="separator" class="divider"></li>
                            <li><a (click)="logout()" class="pointer"><i class="fa fa-power-off"></i> Cerrar Sesión</a></li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
    </nav>
</header>