import { Injectable } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Injectable()
export class ModalUsuarioService {
  public notificacion = new EventEmitter<any>();
  public modalUsuario = false;
  public modalUsuarioEmpresa = false;

  constructor() { }

  mostrarModalUsuario() {
    this.modalUsuario = true;
  }

  ocultarModalUsuario() {
    this.modalUsuario = false;
  }

  mostrarModalUsuarioEmpresa() {
    this.modalUsuarioEmpresa = true;
  }

  ocultarModalUsuarioEmpresa() {
    this.modalUsuarioEmpresa = false;
  }

}
