import { Injectable } from '@angular/core';
import { Cuestionario } from 'src/app/models/cuestionario.model';

@Injectable()
export class CatalogosService {



  constructor(
  ) { }


  cuesrtionarios: string[] = [
    'Primaria',
    'Secundaria',
    'Bachillerato',
    'Técnico',
    'Licenciatura trunca',
    'Licenciatura no titulado',
    'Licenciatura titulado',
    'Maestría',
    'Doctorado',
  ];
 
  totalPaginacion = [5, 10, 15, 20, 25];

  data: any[] = [
    {
      name: 'E',
      series: [
        {
          name: 'Dias',
          value: 11,
          extra: null
        }
      ]
    }
  ];

  cuestionario_2: any[] = [
    {
      ini: 1,
      fin: 9,
      com: 'Para responder las preguntas siguientes considere las condiciones de su centro de trabajo, así como la cantidad y ritmo de trabajo.',
      q: []
    },
    {
      ini: 10,
      fin: 13,
      com: 'Las preguntas siguientes están relacionadas con las actividades que realiza en su trabajo y las responsabilidades que tiene.',
      q: []
    },
    {
      ini: 14,
      fin: 17,
      com: 'Las preguntas siguientes están relacionadas con el tiempo destinado a su trabajo y sus responsabilidades familiares.',
      q: []
    },
    {
      ini: 18,
      fin: 22,
      com: 'Las preguntas siguientes están relacionadas con las decisiones que puede tomar en su trabajo.',
      q: []
    },
    {
      ini: 23,
      fin: 27,
      com: 'Las preguntas siguientes están relacionadas con la capacitación e información que recibe sobre su trabajo.',
      q: []
    },
    {
      ini: 28,
      fin: 40,
      com: 'Las preguntas siguientes se refieren a las relaciones con sus compañeros de trabajo y su jefe.',
      q: []
    },
    {
      ini: 41,
      fin: 43,
      com: 'Las preguntas siguientes están relacionadas con la atención a clientes y usuarios.',
      q: []
    },
    {
      ini: 44,
      fin: 46,
      com: 'Las siguientes preguntas están relacionadas con las actitudes de los trabajadores que supervisa.',
      q: []
    },
  ];

  cuestionario_3: any[] = [
    {
      ini: 1,
      fin: 5,
      com: 'Para responder las preguntas siguientes considere las condiciones ambientales de su centro de trabajo.',
      q: []
    },
    {
      ini: 6,
      fin: 8,
      com: 'Para responder a las preguntas siguientes piense en la cantidad y ritmo de trabajo que tiene.',
      q: []
    },
    {
      ini: 9,
      fin: 12,
      com: 'Las preguntas siguientes están relacionadas con el esfuerzo mental que le exige su trabajo.',
      q: []
    },
    {
      ini: 13,
      fin: 16,
      com: 'Las preguntas siguientes están relacionadas con las actividades que realiza en su trabajo y las responsabilidades que tiene.',
      q: []
    },
    {
      ini: 17,
      fin: 22,
      com: 'Las preguntas siguientes están relacionadas con su jornada de trabajo.',
      q: []
    },
    {
      ini: 23,
      fin: 28,
      com: 'Las preguntas siguientes están relacionadas con las decisiones que puede tomar en su trabajo.',
      q: []
    },
    {
      ini: 29,
      fin: 30,
      com: 'Las preguntas siguientes están relacionadas con cualquier tipo de cambio que ocurra en su trabajo (considere los últimos cambios realizados).',
      q: []
    },
    {
      ini: 31,
      fin: 36,
      com: 'Las preguntas siguientes están relacionadas con la capacitación e información que se le proporciona sobre su trabajo.',
      q: []
    },
    {
      ini: 37,
      fin: 41,
      com: 'Las preguntas siguientes están relacionadas con el o los jefes con quien tiene contacto.',
      q: []
    },
    {
      ini: 42,
      fin: 46,
      com: 'Las preguntas siguientes se refieren a las relaciones con sus compañeros.',
      q: []
    },
    {
      ini: 47,
      fin: 56,
      com: 'Las preguntas siguientes están relacionadas con la información que recibe sobre su rendimiento en el trabajo, el reconocimiento, el sentido de pertenencia y la estabilidad que le ofrece su trabajo.',
      q: []
    },
    {
      ini: 57,
      fin: 64,
      com: 'Las preguntas siguientes están relacionadas con actos de violencia laboral (malos tratos, acoso, hostigamiento, acoso psicológico).',
      q: []
    },
    {
      ini: 65,
      fin: 68,
      com: 'Las preguntas siguientes están relacionadas con la atención a clientes y usuarios.',
      q: []
    },
    {
      ini: 69,
      fin: 72,
      com: 'Las preguntas siguientes están relacionadas con las actitudes de las personas que supervisa.',
      q: []
    },
  ];

  instruccion_head =[
    'Identificación de los trabajadores que fueron sujetos a acontecimientos traumáticos severos',
    'Identificación y análisis de los factores de riesgo psicosocial',
    'Identificación de los factores de riesgo psicosocial y evaluación del entorno organizacional en los centros de trabajo'
  ];


}
