import { Injectable } from '@angular/core';
import { URL_SERVICIOS } from '../../config/config';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Departamento } from 'src/app/models/departamento.model';
import { Periodo } from 'src/app/models/periodo.model';

@Injectable()
export class PeriodoService {

  constructor(
    public http: HttpClient
  ) { }


  obtenerPeriodos(httpParams: HttpParams) {
    let url = `${URL_SERVICIOS}/api/periodos`;
    return this.http.get<any>(url, { params: httpParams });
  }

  crearPeriodo(periodo: Periodo) {
    let url = `${URL_SERVICIOS}/api/periodos`;
    return this.http.post<any>(url, periodo);
  }

  actualizarPeriodos(periodo: Periodo) {
    let url = `${URL_SERVICIOS}/api/periodos/${periodo.idPeriodo}`;
    return this.http.put<any>(url, periodo);
  }

  obtenerPeriodosIdSucursal(idSucursal: number) {
    let url = `${URL_SERVICIOS}/api/periodos/sucursal/${idSucursal}`;
    return this.http.get<any>(url);
  }

}