import { Injectable } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Injectable()
export class ModalService {
  public notificacion = new EventEmitter<any>();
  public modalProd = false;
  public modalPublicacion = false;
  public modalPublicacionAtrr = false;

  constructor() { }

  mostrarModalProd() {
    this.modalProd = true;
  }

  ocultarModalProd() {
    this.modalProd = false;
  }

  mostrarModalPub() {
    this.modalPublicacion = true;
  }

  ocultarModalPub() {
    this.modalPublicacion = false;
  }

  mostrarModalPubAtrr() {
    this.modalPublicacionAtrr = true;
  }

  ocultarModalPubAtrr() {
    this.modalPublicacionAtrr = false;
  }

}
