import { Injectable } from '@angular/core';
import { URL_SERVICIOS } from '../../config/config';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Empresa } from '../../models/empresa.model';

@Injectable()
export class EmpresaService {

  constructor(
    public http: HttpClient
  ) { }

  
  obtenerEmpresas(httpParams: HttpParams) {
    let url = `${URL_SERVICIOS}/api/empresas`;
    return this.http.get<any>(url, { params: httpParams });
  }

  crearEmpresas(empresa: Empresa) {
    let url = `${URL_SERVICIOS}/api/empresas`;
    return this.http.post<any>(url, empresa);
  }

  actualizarEmpresas(empresa: Empresa) {
    let url = `${URL_SERVICIOS}/api/empresas/${empresa.idEmpresa}`;
    return this.http.put<any>(url, empresa);
  }
  
  subirArchivoLogo(archivo: File, idEmpresa: any) {
    
    const url = URL_SERVICIOS + '/api/empresas/upload';

    const formData = new FormData();
    formData.append('archivo', archivo);
    formData.append('idEmpresa', idEmpresa);

    return this.http.post<any>(url, formData);
  }


  obtenerEmpresasPorIdUsuario(idEmpresa:number) {
    let url = `${URL_SERVICIOS}/api/empresas/usuarios/${idEmpresa}`;
    return this.http.get<any>(url);
  }
  
}