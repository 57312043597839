import { Injectable } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Injectable()
export class ModalAgregarLogoService {
  public notificacion = new EventEmitter<any>();
  public modalAgregarLogo = false;

  constructor() { }

  mostrarModalAgregarLogo() {    
    this.modalAgregarLogo = true;
  }

  ocultarModalAgregarLogo() {
    this.modalAgregarLogo = false;
  }

}
