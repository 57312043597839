<aside class="left-sidebar">
    <div class="scroll-sidebar">
        <nav class="sidebar-nav">
            <ul id="sidebarnav">
                <li class="row user-profile">

                    <div class="el-element-overlay">
                        <div class="el-card-item">
                            <div class="el-card-avatar el-overlay-1">
                                <img [src]="url" />

                                <div class="el-overlay" *ngIf="usuario.rol.nombre == 'ROLE_COORDINADOR' || usuario.rol.nombre == 'ROLE_SUPER_ADMIN'">
                                    <ul class="el-info">
                                        <li>
                                            <a class="btn default btn-outline image-popup-vertical-fit" (click)="cambiarImagen()">
                                                <i class="mdi mdi-tooltip-edit"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <span *ngIf="usuario.empresa" class="hide-menu s-c-black" (click)="cambiarImagen()">{{razonSocial}}</span>

                </li>
                <li class="nav-devider"></li>
                <li class="nav-small-cap">Norma 035</li>
                <li *ngFor="let menu of menus">
                    <a class="has-arrow waves-effect waves-dark" href="#" aria-expanded="false">
                        <i class="mdi mdi-settings"></i>
                        <span class="hide-menu">{{menu.nombre}} </span>
                    </a>
                    <ul aria-expanded="false" class="collapse">
                        <li *ngFor="let submenu of menu.subMenus">
                            <a href="{{submenu.url}}">
                                <i class="mdi mdi-clipboard-account"></i> {{submenu.nombre}}
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </nav>
    </div>
</aside>
<app-modals-agregar-logo [usuarioSession]="usuarioSeleccionado"></app-modals-agregar-logo>