import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URL_SERVICIOS } from 'src/app/config/config';

@Injectable()
export class ReporteUtilService {

  public content: any[] = [];

  constructor(
    public http: HttpClient
  ) { }

  crearEncabezados(reporte: any) {
    //Agrega titulo
    this.content = []
    this.content.push({
      alignment: 'center',
      text: 'Reporte de Resultados Evaluación Norma 035',
      style: 'header',
    });
    //Agrega Periodo y encuesta
    let periodo = reporte.periodo ? reporte.periodo.nombre : '';
    let cuestionatio = reporte.cuestionario ? reporte.cuestionario.nombre : '';
    this.content.push({
      alignment: 'justify',
      columns: [
        {
          text: `Periodo: ${periodo}\n`,
          style: 'subheader'
        },
        {
          text: `Encuesta: ${cuestionatio} \n`,
          style: 'subheader'
        },
      ]
    });
    //Agrega Periodo y encuesta
    let depto = reporte.depto ? reporte.depto.nombre : 'todos';
    let puesto = reporte.puesto ? reporte.puesto.nombre : 'todos';
    this.content.push({
      alignment: 'justify',
      columns: [
        {
          text: `Departamento: ${depto} \n`,
          style: 'subheader'
        },
        {
          text: `Puestos: ${puesto} \n`,
          style: 'subheader'
        },
      ]
    });
  }
 
  agregarTablasFijas(tablasFijas: any = []) {
    //Agrega tabla de necesidades de accion
    this.content.push({
      style: 'tableNecAccion',
      table: {
        widths: [100, '*'],
        heights: [20, 20, 20, 20, 20],
        body: tablasFijas
      },
      layout: 'noBorders'
    });
  }
  agregarTablasContenidoGuiaI(tablaContenido: any = []) {
    //Agrega tabla de necesidades de accion
    this.content.push({
      style: 'tableResultadoGuiaI',
      table: {
        widths: [50,100,100,90,40,40,40,40],
        body: tablaContenido
      },
      layout: 'noBorders'

    });
  }

  agregarTablasContenidoCat(tablaContenido: any = []) {
    //Agrega tabla de necesidades de accion
    //Agrega tabla de resultados
    this.content.push({
      style: 'tableResultado',
      table: {
        widths: [80,80,100,150,40,80],
        body: tablaContenido
      },
      layout: 'noBorders'
    });
  }

  agregarTablasContenidoDom(tablaContenido: any = []) {
    //Agrega tabla de necesidades de accion
    //Agrega tabla de resultados
    this.content.push({
      style: 'tableResultado',
      table: {
        // widths: [80,80,80,150,40,80,80],
        body: tablaContenido
      },
      layout: 'noBorders'
    });
  }

  agregarTablasContenidoInd(tablaContenido: any = []) {
    //Agrega tabla de necesidades de accion
    //Agrega tabla de resultados
    this.content.push({
      style: 'tableResultado',
      table: {
        widths: [200,100,100,50,80],
        body: tablaContenido
      },
      layout: 'noBorders'
    });
  }
  obtenerPdf(reporte: any) {
    let content = this.content
    var docDefinition = {
      footer: function (currentPage, pageCount) {
        return [
          {
            text: 'Pagina ' + currentPage.toString(),
            alignment: 'right',
            margin: [20, 0]
          },
        ]
      },
      header:
        [
          {
            columns: [
              {
                image: 'snow',
                width: 60,
                margin: [20, 10, 0, 0],
              },
              {
                text: [
                  { text: `${reporte.empresa.razonSocial}\n`, alignment: 'center', bold: false, style: 'headerEmpresa' },
                  {
                    text: [
                      { text: `Centro Trabajo: `, fontSize: 10, bold: false, alignment: 'center' },
                      { text: `${reporte.sucursal.nombre}`, fontSize: 14, bold: true, alignment: 'center' }
                    ]
                  }
                ],
                margin: [40, 20, 0, 0],
              },

            ]
          },
          { canvas: [{ type: 'line', x1: 10, y1: 10, x2: 595 - 10, y2: 10, lineWidth: 0.5 }] }
        ]
      ,
      pageMargins: [20, 80, 20, 30],
      content,
      images: {
        mySuperImage: 'data:image/jpeg;base64,...content...',
        snow: URL_SERVICIOS + '/api/empresas/uploads/img/' + reporte.empresa.logo,
      },

      styles: {
        header: {
          fontSize: 16,
          bold: true,
          margin: [0, 0, 0, 7],
        },
        subheader: {
          fontSize: 13,
          margin: [0, 0, 0, 5],
        },
        tableNecAccion: {
          // margin: [10, 0, 0, 0],
          fontSize: 9,
        },
        tableResultado: {
          margin: [0, 5, 0, 15],
          fontSize: 11,
        },
        tableResultadoGuiaI: {
          margin: [0, 5, 0, 15],
          fontSize: 10,
        },
        headerInd: {
          fontSize: 18,
          bold: true,
          margin: [0, 0, 0, 7],
        },

        headerEmpresa: {
          fontSize: 18,
          bold: true,
          margin: [10, 0, 0, 7],
        },
        headerCt: {
          fontSize: 16,
          bold: true,
          margin: [0, 0, 0, 7],
        },
        subheaderInd: {
          fontSize: 13,
          bold: true,
          margin: [0, 0, 0, 5],
        },
      }
    };
    return docDefinition;
  }

  
  obtenerColor(nivelRiesgo: string) {

    let clase;
    switch (nivelRiesgo) {
      case 'MUY ALTO':
        clase = '#ff3500';
        break;
      case 'ALTO':
        clase = '#ff8000';
        break;
      case 'MEDIO':
        clase = '#fdf731';
        break;
      case 'BAJO':
        clase = '#16a53e';
        break;
      case 'NULO':
        clase = '#00bff3';
        break;
      default: clase = '';
        break;
    }

  }



}