<link href="https://fonts.googleapis.com/css2?family=Roboto&display=swap" rel="stylesheet">
<div id="main-wrapper">

  <app-header></app-header>

  <app-sidebar></app-sidebar>

  <div class="page-wrapper">
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div>

  </div>
</div>

<ngx-spinner bdColor="rgba(247,249,251,1)" size="large" color="#00a7e1" type="ball-clip-rotate la-3x">

</ngx-spinner>
