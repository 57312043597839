import { Injectable } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Injectable()
export class ModalBienvenidaEvaluacionService {
  public notificacion = new EventEmitter<any>();
  public modalBienvenidaEvaluacion = false;

  constructor() { }

  mostrarModalBienvenidaEvaluacion() {    
    this.modalBienvenidaEvaluacion = true;
  }

  ocultarModalBienvenidaEvaluacion() {
    this.modalBienvenidaEvaluacion = false;
  }

}
