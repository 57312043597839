import { Injectable } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Injectable()
export class ModalAgregarArchivoService {
  public notificacion = new EventEmitter<any>();
  public modalAgregarArchivo = false;

  constructor() { }

  mostrarModalAgregarArchivo() {    
    this.modalAgregarArchivo = true;
  }

  ocultarModalAgregarArchivo() {
    this.modalAgregarArchivo = false;
  }

}
