import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  AuthService,
  ModalAgregarLogoService,
  EmpresaService,
  UtilService
} from 'src/app/services/service.index';
import swal from 'sweetalert2';
import { Usuario } from 'src/app/models/usuario.model';


@Component({
  selector: 'app-modals-agregar-logo',
  templateUrl: './modals-agregar-logo.component.html',
  styleUrls: ['./modals-agregar-logo.component.css']
})
export class ModalsAgregarLogoComponent implements OnInit, OnChanges{

  @Input() usuarioSession: Usuario;

  url: string;
  fotoSeleccionada: File;
  imagenTemp: string = null;
  textoLogo = '';
  textoDefLogo = 'Adjuntar imagen';

  public archivoSeleccionado: File;

  constructor(
    public spinner: NgxSpinnerService,
    public authService: AuthService,
    public modalAgregarLogoService: ModalAgregarLogoService,
    public empresaService: EmpresaService,
    public utilService: UtilService,
  ) { }

  ngOnInit(): void {
    
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.usuarioSession && this.usuarioSession.empresa.logo != null && this.usuarioSession != null) {
      this.init();
    }
  }

  init() {
    this.setNombreArchivoCv(this.usuarioSession.empresa.logo);
    let nombreLogo = (!this.usuarioSession.empresa.logo)? 'default': (this.usuarioSession.empresa.logo);
    this.url = this.utilService.obtenerUrlLogo(nombreLogo);
  }

  setNombreArchivoCv(archivo: any) {
    this.textoLogo = this.textoDefLogo;
  }

  cerrarModal() {
    
    this.modalAgregarLogoService.ocultarModalAgregarLogo();
  }

  seleccionarArchivo(event) {
      
    this.fotoSeleccionada = event.target.files[0];
    if (event.target.files[0]){
      this.textoLogo = this.textoDefLogo;
      
      if (this.fotoSeleccionada.type.indexOf('image') < 0){
        swal.fire('Importante ', 'El archivo debe ser del tipo imagen', 'warning');
        this.fotoSeleccionada = null;
        return;
      }
      this.textoLogo = this.fotoSeleccionada.name;
      const reader = new FileReader();
      const urlImagenTemp = reader.readAsDataURL(this.fotoSeleccionada);

      reader.onloadend = () => {
        this.imagenTemp = reader.result as string;
      }
    }
  }

  subir(){
    if (!this.fotoSeleccionada){
      swal.fire('Importante', 'El archivo debe ser del tipo imagen', 'warning');
    }else{
      this.subirArchivo()
    }
  }

  subirArchivo() {
    this.spinner.show();
    const idEmpresa= this.usuarioSession.empresa.idEmpresa;
    this.empresaService.subirArchivoLogo(this.fotoSeleccionada,idEmpresa).subscribe(resp => {
      if (resp.success){
        swal.fire('Exito', resp.descripcion, 'success');
        this.usuarioSession.empresa.logo= resp.body.logo
        this.imagenTemp= null;
        this.authService.actualizarUsuario(this.usuarioSession);
        this.url = this.utilService.obtenerUrlLogo(this.usuarioSession.empresa.logo);
        this.modalAgregarLogoService.notificacion.emit(this.usuarioSession);
        
      } else if (resp.descripcion) {
        swal.fire('Error', resp.descripcion, 'error');
      }
      this.cerrarModal();
      this.spinner.hide();
    });
    
  }

}
