import { Injectable } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Injectable()
export class ModalPeriodoService {
  public notificacion = new EventEmitter<any>();
  public modalPeriodo = false;

  constructor() { }

  mostrarModalPeriodo() {
    this.modalPeriodo = true;
  }

  ocultarModalPeriodo() {
    this.modalPeriodo = false;
  }

}
