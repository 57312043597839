import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { CoreComponent } from './core.component';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: CoreComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'inicio', component: HomeComponent },
      {
        path: 'evaluaciones',
        loadChildren: () => import('../evaluaciones/evaluaciones.module').then(m => m.EvaluacionesModule)
      },
      {
        path: 'configuracion',
        loadChildren: () => import('../configuracion/configuracion.module').then(m => m.ConfiguracionModule)
      },
      {
        path: 'empleados',
        loadChildren: () => import('../empleados/empleados.module').then(m => m.EmpleadosModule)
      },
      { path: '', redirectTo: '/login', pathMatch: 'full' },
    ]
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CoreRoutingModule { }
