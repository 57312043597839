
export const INDIVIDUAL = 'INDIVIDUAL' ;
export const CATEGORIAS = 'CATEGORIAS' ;
export const DOMINIO = 'DOMINIO';
export const GUIAI = 'GUIA';


export const INDIVIDUAL_II = 'INDIVIDUAL_II';
export const INDIVIDUAL_III = 'INDIVIDUAL_III';
export const CATEGORIA_II = 'CATEGORIA_II';
export const CATEGORIA_III = 'CATEGORIA_III';
export const DOMINIO_II = 'DOMINIO_II';
export const DOMINIO_III = 'DOMINIO_III';

export const ROLE_COORDINADOR = 'ROLE_COORDINADOR';
export const ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN';
export const ROLE_SUCURSAL = 'ROLE_SUCURSAL';
export const ROLE_EVALUADO = 'ROLE_EVALUADO';

export const ID_INDIVIDUAL = 1;
export const ID_CATEGORIAS = 2;
export const ID_DOMINIO = 3;
export const ID_GUIAI = 4;



