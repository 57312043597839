import { Injectable } from '@angular/core';
import { URL_SERVICIOS } from '../../config/config';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Sucursal } from 'src/app/models/sucursal.model';

@Injectable()
export class SucursalService {

  constructor(
    public http: HttpClient
  ) { }

  crearSucursal(sucursal: Sucursal) {
    let url = `${URL_SERVICIOS}/api/sucursales`;
    return this.http.post<any>(url, sucursal);
  }

  actualizarSucursal(sucursal: Sucursal) {
    let url = `${URL_SERVICIOS}/api/sucursales/${sucursal.idSucursal}`;
    return this.http.put<any>(url, sucursal);
  }

  obtenerSucursal(params: HttpParams) {
    let url = `${URL_SERVICIOS}/api/sucursales`;
    return this.http.get<any>(url);
  }

  obtenerSucursalesIdUsuario(idUsuario: number) {
    let url = `${URL_SERVICIOS}/api/sucursales/usuarios/${idUsuario}`;
    return this.http.get<any>(url);
  }

  obtenerSucursalesIdEmpresa(idEmpresa: number) {
    let url = `${URL_SERVICIOS}/api/sucursales/empresa/${idEmpresa}`;
    return this.http.get<any>(url);
  }


}