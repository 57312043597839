import { Injectable } from '@angular/core';
import { URL_SERVICIOS } from '../../config/config';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AsocCuestionario } from 'src/app/models/asociar-cuestionario.model';
import { AsocDesaCuestionario } from 'src/app/models/aso-desa-cuestionario.model';

@Injectable()
export class AsocEmpleadoService {

  constructor(
    public http: HttpClient
  ) { }

  obtenerEmpleadosCuestionario(httpParams: HttpParams) {
    let url = `${URL_SERVICIOS}/api/asociacion/empleados`;
    return this.http.get<any>(url, { params: httpParams });
  }

  enviarCuestionariosEmpleados(asocCuestionario: AsocCuestionario) {
    let url = `${URL_SERVICIOS}/api/asociacion/empleados/envio`;
    return this.http.post<any>(url, asocCuestionario);
  }

  asoDesaCuestionariosEmpleados(asocDesaCuestionario: AsocDesaCuestionario) {
    let url = `${URL_SERVICIOS}/api/asociar-desasociar/empleados`;
    return this.http.post<any>(url, asocDesaCuestionario);
  }

}