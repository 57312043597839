import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoreRoutingModule } from './core-routing.module';
import { CoreComponent } from './core.component';
import { SharedModule } from '../shared/shared.module';
import { HomeComponent } from './components/home/home.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { ServicesModule } from '../services/services.module';
import { TokenInterceptor } from './interceptor/token.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './interceptor/auth.interceptor';




@NgModule({
  declarations: [CoreComponent, HomeComponent],
  imports: [
    CommonModule,
    CoreRoutingModule,
    SharedModule,
    NgxSpinnerModule,
    ServicesModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }

  ],
})
export class CoreModule { }
