import { Component, OnInit } from '@angular/core';
import { Menu } from 'src/app/models/menu.model';
import { Usuario } from 'src/app/models/usuario.model';
import { AuthService, MenuService, ModalAgregarLogoService, UtilService } from 'src/app/services/service.index';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  public usuario: Usuario;
  public  menus: Menu[] = [];
  usuarioSeleccionado: Usuario;
  url: string= 's-img';
  razonSocial: string;
  constructor(
    private authService: AuthService,
    public menuService: MenuService,
    public modalAgregarLogoService: ModalAgregarLogoService,
    public utilService: UtilService
  ) { }

  ngOnInit(): void {
    
    this.usuario = this.authService.usuarioSesion;
    
    this.obtenerNombreRazonSocial();
    
    this.menus = this.authService.menuSesion;
    this.url = (this.usuario.empresa?.logo != null)? this.utilService.obtenerUrlLogo(this.usuario.empresa.logo):this.utilService.obtenerUrlLogo(this.url) ;
    this.modalAgregarLogoService.notificacion.subscribe(resp =>{
      
      if(resp.idUsuario){
        this.url= this.utilService.obtenerUrlLogo(resp.empresa.logo);
        this.usuario= this.authService.usuarioSesion;
        this.obtenerNombreRazonSocial();
      }
    });
  }

  obtenerNombreRazonSocial(){
    if(this.usuario.empresa && this.usuario.empresa.razonSocial){
      this.razonSocial = (this.usuario.empresa.razonSocial).length > 15 ? (this.usuario.empresa.razonSocial).substr(0, 15) + '...' : (this.usuario.empresa.razonSocial);
    }else {
      this.razonSocial = "Norma 035"
    }
  }

  cambiarImagen(){
    if(this.usuario.rol.nombre == 'ROLE_COORDINADOR' || this.usuario.rol.nombre == 'ROLE_SUPER_ADMIN'){
      this.mostrarModalLogos();
    }
  }

  mostrarModalLogos(){  
    this.modalAgregarLogoService.mostrarModalAgregarLogo();
    this.usuarioSeleccionado = { ...this.usuario }
  }

}
