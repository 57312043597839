import { Injectable } from '@angular/core';
import { ID_CATEGORIAS, ID_DOMINIO, ID_GUIAI, ID_INDIVIDUAL } from 'src/app/constantes/constantes';
import { ReporteUtilService } from './reporte.util.service';

@Injectable()
export class ReporteGuiasService {

  public necesidadesAcciones: any[] = [
    { color: '', nivelRiesgo: 'MUY ALTO', necesidad: 'Se requiere realizar el análisis de cada categoría y dominio para establecer las acciones de intervención apropiadas, mediante un programa de intervención que deberá incluir evaluaciones específicas, y contemplar campañas de sensibilización, revisar la política de prevención de riesgos psicosociales y programas para la prevención de los factores de riesgo psicosocial, la promoción de un entorno organizacional favorable y la prevención de la violencia laboral, así como reforzar su aplicación y difusión.' },
    { nivelRiesgo: 'ALTO', necesidad: 'Se requiere realizar un análisis de cada categoría y dominio, de manera que se puedan determinar las acciones de intervención apropiadas a través de un programa de intervención, que podrá incluir una evaluación específica y deberá incluir una campaña de sensibilización, revisar la política de prevención de riesgos psicosociales y programas para la prevención de los factores de riesgo psicosocial, la promoción de un entorno organizacional favorable y la prevención de la violencia laboral, así como reforzar su aplicación y difusión.' },
    { nivelRiesgo: 'MEDIO', necesidad: 'Se requiere revisar la política de prevención de riesgos psicosociales y programas para la prevención de los factores de riesgo psicosocial, la promoción de un entorno organizacional favorable y la prevención de la violencia laboral, así como reforzar su aplicación y difusión, mediante un programa de intervención.' },
    { nivelRiesgo: 'BAJO', necesidad: 'Es necesario una mayor difusión de la política de prevención de riesgos psicosociales y programas para: la prevención de los factores de riesgo psicosocial, la promoción de un entorno organizacional favorable y la prevención de la violencia laboral.' },
    { nivelRiesgo: 'NULO', necesidad: 'El riesgo resulta despreciable por lo que no se requieren medidas adicionales.' },

  ];

  public rangosEvaluacion: any[] = [
    { seccion: 'I', rangoEva: 'Si tiene de resultado 1 o más en la sección se requiere evaluar las demás secciones(II,III y IV),en caso de que no, no es necesario evaluar las demás secciones, y el trabajador no requiere una valoración clínica.' },
    { seccion: 'II', rangoEva: 'Si tiene de resultado 1 o más en la sección, el trabajador requiere una valoración clínica.' },
    { seccion: 'III', rangoEva: 'Si tiene de resultado 3 o más en la sección, el trabajador requiere una valoración clínica.' },
    { seccion: 'IV', rangoEva: 'Si tiene de resultado 2 o más en la sección, el trabajador requiere una valoración clínica.' },

  ];

  public resultados: any[] = [
    { necesitaValoracion: true, resultado: 'I', desc: 'El trabajador SÍ requerirá de una valoración clínica.' },
    { necesitaValoracion: false, resultado: 'II', desc: 'El trabajador NO requerirá de una valoración clínica.' },

  ];
  constructor(
    public reporteUtilService: ReporteUtilService
  ) { }


  crearReporte(reporte: any) {
    switch (reporte.tipoEvaluacion) {
      case ID_INDIVIDUAL:

        return this.agregarContenidoIndivual(reporte);

      case ID_CATEGORIAS:

        return this.agregarContenidoCategorias(reporte);

      case ID_DOMINIO:

        return this.agregarContenidoDominio(reporte);
      case ID_GUIAI:

        return this.crearContenidoGuiaI(reporte);
    }

  }

  crearContenidoGuiaI(reporte) {

    this.reporteUtilService.crearEncabezados(reporte);

    let rangosEva = [];
    rangosEva.push([
      { fillColor: '#026298', text: 'Sección', color: 'white' },
      { fillColor: '#026298', text: 'Rangos y evaluación', color: 'white' }
    ]);
    this.rangosEvaluacion.forEach(element => {
      rangosEva.push([
        { text: element.seccion, alignment: 'center' },
        { text: element.rangoEva, alignment: 'justify' }
      ]);
    });
    this.reporteUtilService.agregarTablasFijas(rangosEva);

    let res = [];
    res.push([
      { fillColor: '#026298', text: 'Resultado', color: 'white' },
      { fillColor: '#026298', text: 'Descripción', color: 'white' }
    ]);


    this.resultados.forEach(element => {

      res.push([
        { fillColor: this.obtenerColorValoracion(element.necesitaValoracion), text: element.resultado, alignment: 'center' },
        { text: element.desc }
      ]);
    });
    this.reporteUtilService.agregarTablasFijas(res);

    let tablaContenido: any[] = [];
    tablaContenido.push([
      { fillColor: '#026298', text: 'Requiere Atencion clínica', color: 'white' },
      { fillColor: '#026298', text: 'Nombre', color: 'white' },
      { fillColor: '#026298', text: 'Departamento', color: 'white' },
      { fillColor: '#026298', text: 'Puesto', color: 'white' },
      { fillColor: '#026298', text: 'sección I', color: 'white' },
      { fillColor: '#026298', text: 'sección II', color: 'white' },
      { fillColor: '#026298', text: 'sección III', color: 'white' },
      { fillColor: '#026298', text: 'sección IV', color: 'white' }
    ]);

    reporte.reportes.forEach(element => {
      tablaContenido.push([
        { fillColor: this.obtenerColorValoracion(element.reqAtencion), text: element.reqAtencion ? 'SÍ' : 'NO',alignment: 'center' },
        { text: element.empleado.usuario.nombre },
        { text: element.empleado.puesto.departamento.nombre },
        { text: element.empleado.puesto.nombre },
        { text: element.puntuajeUno ? element.puntuajeUno : '0' },
        { text: element.puntuajeDos ? element.puntuajeDos : '0' },
        { text: element.puntuajeTres ? element.puntuajeTres : '0' },
        { text: element.puntuajeCuatro ? element.puntuajeCuatro : '0' }
      ]);
    });

    this.reporteUtilService.agregarTablasContenidoGuiaI(tablaContenido);
    return this.reporteUtilService.obtenerPdf(reporte);
  }

  agregarContenidoIndivual(reporte: any) {

    this.crearContenidoFijoGuiasIAndII(reporte);
    let tablaContenido: any[] = [];
    tablaContenido.push([
      { fillColor: '#026298', text: 'Nombre', color: 'white' },
      { fillColor: '#026298', text: 'Departamento', color: 'white' },
      { fillColor: '#026298', text: 'Puesto', color: 'white' },
      { fillColor: '#026298', text: 'Puntos', color: 'white' },
      { fillColor: '#026298', text: 'Nivel Riesgo', color: 'white' },
    ]);

    reporte.reportes.forEach(element => {
      tablaContenido.push([
        { text: `${element.empleado.usuario.nombre}  ${element.empleado.usuario.apePaterno}` },
        { text: element.empleado.puesto.departamento.nombre },
        { text: element.empleado.puesto.nombre },
        { text: element.puntos },
        { fillColor: this.obtenerColor(element.nivelRiesgo), text: element.nivelRiesgo, color: 'white', bold: true }
      ]);
    });
    this.reporteUtilService.agregarTablasContenidoInd(tablaContenido);
    return this.reporteUtilService.obtenerPdf(reporte);
  }



  agregarContenidoCategorias(reporte: any) {
    this.crearContenidoFijoGuiasIAndII(reporte);
    let tablaContenido: any[] = [];
    tablaContenido.push([
      { fillColor: '#026298', text: 'Nombre', color: 'white' },
      { fillColor: '#026298', text: 'Departamento', color: 'white' },
      { fillColor: '#026298', text: 'Puesto', color: 'white' },
      { fillColor: '#026298', text: 'Categoría', color: 'white' },
      { fillColor: '#026298', text: 'Puntos', color: 'white' },
      { fillColor: '#026298', text: 'Nivel Riesgo', color: 'white' }
    ]);

    reporte.reportes.forEach(element => {
      tablaContenido.push([
        { text: element.empleado.usuario.nombre },
        { text: element.empleado.puesto.departamento.nombre },
        { text: element.empleado.puesto.nombre },
        { text: element.categoria.nombre },
        { text: element.puntos },
        { fillColor: this.obtenerColor(element.nivelRiesgo), text: element.nivelRiesgo, color: 'white', bold: true }
      ]);
    });
    this.reporteUtilService.agregarTablasContenidoCat(tablaContenido);
    return this.reporteUtilService.obtenerPdf(reporte);
  }

  agregarContenidoDominio(reporte: any) {
    this.crearContenidoFijoGuiasIAndII(reporte);
    let tablaContenido: any[] = [];

    tablaContenido.push([
      { fillColor: '#026298', text: 'Nombre', color: 'white' },
      { fillColor: '#026298', text: 'Departamento', color: 'white' },
      { fillColor: '#026298', text: 'Puesto', color: 'white' },
      { fillColor: '#026298', text: 'Categoría', color: 'white' },
      { fillColor: '#026298', text: 'Dominio', color: 'white' },
      { fillColor: '#026298', text: 'Nivel Riesgo', color: 'white' },
      { fillColor: '#026298', text: 'Nombre', color: 'white' },
    ]);

    reporte.reportes.forEach(element => {
      tablaContenido.push([
        { text: element.empleado.usuario.nombre },
        { text: element.empleado.puesto.departamento.nombre },
        { text: element.empleado.puesto.nombre },
        { text: element.categoria.nombre },
        { text: element.dominio.nombre },
        { text: element.puntos },
        { fillColor: this.obtenerColor(element.nivelRiesgo), text: element.nivelRiesgo, color: 'white', bold: true }
      ]);
    });
    this.reporteUtilService.agregarTablasContenidoDom(tablaContenido);

    return this.reporteUtilService.obtenerPdf(reporte);
  }

  crearContenidoFijoGuiasIAndII(reporte: any) {
    this.reporteUtilService.crearEncabezados(reporte);

    let acciones = [];
    this.necesidadesAcciones.forEach(element => {

      acciones.push([
        { fillColor: this.obtenerColor(element.nivelRiesgo), text: element.nivelRiesgo, color: 'white', bold: true },
        { text: element.necesidad, alignment: 'justify', }
      ]);
    });
    this.reporteUtilService.agregarTablasFijas(acciones);

  }

  obtenerColor(nivelRiesgo: string) {

    let clase;
    switch (nivelRiesgo) {
      case 'MUY ALTO':
        clase = '#ff3500';
        break;
      case 'ALTO':
        clase = '#ff8000';
        break;
      case 'MEDIO':
        clase = '#fdf731';
        break;
      case 'BAJO':
        clase = '#16a53e';
        break;
      case 'NULO':
        clase = '#00bff3';
        break;
      default: clase = '';
        break;
    }

    return clase;
  }

  obtenerColorValoracion(necesitaValoracion: boolean) {

    let clase;
    switch (necesitaValoracion) {
      case true:
        clase = '#ff3500';
        break;
      case false:
        clase = '#00bff3';
        break;
      default: clase = '';
        break;
    }

    return clase;
  }

}