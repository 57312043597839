import { Injectable } from '@angular/core';
import { URL_SERVICIOS } from '../../config/config';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable()
export class ReporteService {

  constructor(
    public http: HttpClient
  ) { }


  obtenerReportesGuias(httpParams: HttpParams) {
    let url = `${URL_SERVICIOS}/api/reportes/guias`;
    return this.http.get<any>(url, { params: httpParams });
  }


  obtenerReporteGuiaI(httpParams: HttpParams) {
    let url = `${URL_SERVICIOS}/api/reportes/guia-uno`;
    return this.http.get<any>(url, { params: httpParams });
  }

  obtenerReporteCategoriasExcel(httpParams: HttpParams) {
    let url = `${URL_SERVICIOS}/api/reportes/excel/categorias`;
    return this.http.get<any>(url, { params: httpParams });
  }

  obtenerReporteIndividual(httpParams: HttpParams) {
    let url = `${URL_SERVICIOS}/api/reportes/ind`;
    return this.http.get<any>(url, { params: httpParams });
  }

  obtenerReporteIndividualGuiaI(httpParams: HttpParams) {
    let url = `${URL_SERVICIOS}/api/reportes/ind/guia-uno`;
    return this.http.get<any>(url, { params: httpParams });
  }


}