import { Injectable } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Injectable()
export class ModalEmpleadoCuestionarioService {
  public notificacion = new EventEmitter<any>();
  public modalEmpCuestionario = false;

  constructor() { }

  mostrarModalEmpCuestionario() {
    this.modalEmpCuestionario = true;
  }

  ocultarModalEmpCuestionario() {
    this.modalEmpCuestionario = false;
  }

}
