import { Injectable } from '@angular/core';
import { URL_SERVICIOS } from '../../config/config';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Empleado } from 'src/app/models/empleado.model';

@Injectable()
export class EmpleadoService {

  constructor(
    public http: HttpClient
  ) { }

  obtenerEmpleados(httpParams: HttpParams) {
    let url = `${URL_SERVICIOS}/api/empleados`;
    return this.http.get<any>(url, { params: httpParams });
  }

  crearEmpleado(empleado: Empleado) {
    let url = `${URL_SERVICIOS}/api/empleados`;
    return this.http.post<any>(url, empleado);
  }

  actualizarEmpleado(empleado: Empleado) {
    let url = `${URL_SERVICIOS}/api/empleados/${empleado.idEmpleado}`;
    return this.http.put<any>(url, empleado);
  }

  desactivarEmpleado(empleado: Empleado) {
    let url = `${URL_SERVICIOS}/api/empleados/activos/${empleado.idEmpleado}`;
    return this.http.put<any>(url, empleado);
  }

}