<div id="responsive-modal" class="fondo-negro" *ngIf="modalAgregarLogoService.modalAgregarLogo">
    <div class="modal-dialog modal-admin">
        <div class="modal-content">
            <div class="modal-header">
                <p class="heading">{{false ? 'Editar':'Agregar' }} Logo</p>
                <button type="button" (click)="cerrarModal()" class="close" aria-hidden="true">
                    <span class="white-text">×</span> 
                </button>
            </div>
            <div class="modal-body">
                <!-- <label for="">Arrastre o jale imagen para sustituir su logo </label> -->

                <div class="offset-lg-2 col-lg-8 offset-md-2 col-md-8 col-sm-12 text-center">

                    <!-- <img *ngIf="true" class="model_img img-responsive img-thumbnail " width="150" height="150" src="../assets/images/users/profile.png"> -->

                    <img *ngIf="imagenTemp == null && usuarioSession?.empresa?.logo != null" [src]="url" alt="{{usuarioSession.nombre}}" class="model_img img-responsive img-thumbnail ">
                    <img *ngIf="imagenTemp  != null" [src]="imagenTemp" class="model_img img-responsive img-thumbnail">
                    <img *ngIf="imagenTemp  == null && usuarioSession?.empresa?.logo == null" src="../assets/images/users/profile.png" class="model_img img-responsive img-thumbnail ">


                </div>
                <div class="input-group mb-3 m-t-20">
                    <div class="custom-file">
                        <input (change)="seleccionarArchivo($event)" type="file" class="custom-file-input" id="inputGroupFile04" aria-describedby="inputGroupFileAddon04">
                        <label class="custom-file-label" for="inputGroupFile04">
                                {{textoLogo}}
                        </label>
                    </div>
                    <div class="input-group-append">
                        <button (click)="subir()" [disabled]="!fotoSeleccionada" class="btn btn-outline-secondary" type="button" id="inputGroupFileAddon04">Subir</button>
                    </div>
                </div>
                <!-- <div class="col-12 m-t-10 ">
                    <ngx-dropzone (change)="onSelect($event)" required accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
                        <ngx-dropzone-label>Arrastre y suelte aquí el archivo XLSX, o dar click!</ngx-dropzone-label>
                        <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
                            <ngx-dropzone-label>{{textoArchivo}}</ngx-dropzone-label>
                        </ngx-dropzone-preview>
                    </ngx-dropzone>
                </div>
                <div class="col-lg-12 p-t-40 p-b-40 m-t-20">
                    <div class="offset-lg-3 col-lg-6">
                        <button (click)="subirArchivo()" [disabled]="!archivoSeleccionado" class="btn btn-blue-admin" type="button" id="inputGroupFileAddon04" style="width: 100%;">Subir Archivo</button>
                    </div>
                </div> -->

            </div>


        </div>
    </div>
</div>