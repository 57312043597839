import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AuthService,
  SessionService,
  ModalService,
  ModalUsuarioService,
  UtilService,
  CatalogosService,
  ModalEmpresaService,
  ModalSucursalService,
  EmpresaService,
  SucursalService,
  UsuarioService,
  EvaluacionesService,
  DepartamentoService,
  PuestoService,
  ModalDepartamentoService,
  ModalPuestoService,
  MenuService,
  PeriodoService,
  ModalPeriodoService,
  EmpleadoService,
  ModalEmpleadoService,
  ValidacionesService,
  AsocEmpleadoService,
  ModalEmpleadoCuestionarioService,
  UploadEmpleadoService,
  ModalAgregarArchivoService,
  ReporteService,
  ReporteUtilService,
  ModalAgregarLogoService,
  ReporteGuiasService,
  ReporteIndService,
  ModalBienvenidaEvaluacionService,
  ModalInstruccionesService
} from './service.index';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [],
  imports: [CommonModule, HttpClientModule],
  providers: [
    AuthService,
    
    SessionService,
    ModalService,
    ModalUsuarioService,
    UtilService,
    CatalogosService,
    ModalEmpresaService,
    ModalSucursalService,
    EmpresaService,
    SucursalService,
    UsuarioService,
    EvaluacionesService,
    DepartamentoService,
    PuestoService,
    ModalDepartamentoService,
    ModalPuestoService,
    MenuService,
    PeriodoService,
    ModalPeriodoService,
    EmpleadoService,
    ModalEmpleadoService,
    ValidacionesService,
    AsocEmpleadoService,
    ModalEmpleadoCuestionarioService,
    UploadEmpleadoService,
    ModalAgregarArchivoService,
    ReporteService,
    ReporteUtilService,
    ModalAgregarLogoService,
    ReporteGuiasService,
    ReporteIndService,
    ModalBienvenidaEvaluacionService,
    ModalInstruccionesService
  ],

})
export class ServicesModule { }
