import { Injectable } from '@angular/core';
import { URL_SERVICIOS } from '../../config/config';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class UploadEmpleadoService {

  constructor(
    public http: HttpClient
  ) { }

  cargaMasivaEmpleados(archivo: File, idSucursal: number) {
    let url = `${URL_SERVICIOS}/api/empleados/upload`;
    const formData = new FormData();
    formData.append('archivo', archivo);
    formData.append('idSucursal', idSucursal.toString());
    return this.http.post<any>(url, formData);
  }



}