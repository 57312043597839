import { Injectable } from '@angular/core';
import { URL_SERVICIOS } from '../../config/config';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Respuesta } from 'src/app/models/respuesta.model';


@Injectable()
export class EvaluacionesService {

  constructor(
    public http: HttpClient
  ) { }

  obtenerCuestionariosPorIdEmpleado(idEmpleado: number) {
    let url = `${URL_SERVICIOS}/api/cuestionarios/empleados/${idEmpleado}`;
    return this.http.get<any>(url);
  }

  obtenerCuestionarioPorId(idCuestionario: number, idEmpleado: number) {
    let url = `${URL_SERVICIOS}/api/cuestionarios/${idCuestionario}/empleados/${idEmpleado}/preguntas`;
    return this.http.get<any>(url);
  }

  respuestas(respuesta: Respuesta[]) {
    let url = `${URL_SERVICIOS}/api/respuestas`;
    return this.http.post<any>(url, respuesta);
  }

  obtenerAllCuestionariosPorIdEmpleado(idEmpleado: number) {
    let url = `${URL_SERVICIOS}/api/cuestionarios/empleados/${idEmpleado}/all`;
    return this.http.get<any>(url);
  }

  obtenerCuestionariosPorIdPeriodo(idPeriodo: number) {
    let url = `${URL_SERVICIOS}/api/cuestionarios/periodos/${idPeriodo}`;
    return this.http.get<any>(url);
  }
}
