import { Injectable } from '@angular/core';
import { URL_SERVICIOS } from '../../config/config';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Departamento } from 'src/app/models/departamento.model';

@Injectable()
export class DepartamentoService {

  constructor(
    public http: HttpClient
  ) { }


  obtenerDepartamentos(httpParams: HttpParams) {
    let url = `${URL_SERVICIOS}/api/departamentos`;
    return this.http.get<any>(url, { params: httpParams });
  }

  crearDepartamento(departamento: Departamento) {
    let url = `${URL_SERVICIOS}/api/departamentos`;
    return this.http.post<any>(url, departamento);
  }

  actualizarDepartamento(departamento: Departamento) {
    let url = `${URL_SERVICIOS}/api/departamentos/${departamento.idDepartamento}`;
    return this.http.put<any>(url, departamento);
  }

  obtenerDepartamentosPorIdSucursal(httpParams: HttpParams) {
    let url = `${URL_SERVICIOS}/api/departamentos/sucursal`;
    return this.http.get<any>(url, { params: httpParams });
  }

}