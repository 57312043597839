import { Injectable } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Injectable()
export class ModalInstruccionesService {
  public notificacion = new EventEmitter<any>();
  public modalInstrucciones = false;

  constructor() { }

  mostrarModalInstrucciones() {    
    this.modalInstrucciones = true;
  }

  ocultarModalInstrucciones() {
    this.modalInstrucciones = false;
  }

}
