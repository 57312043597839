import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Rol } from 'src/app/models/rol.model';
import { Sucursal } from 'src/app/models/sucursal.model';
import { Usuario } from 'src/app/models/usuario.model';
import { AuthService, SucursalService } from 'src/app/services/service.index';
import { NgxSpinnerService } from 'ngx-spinner';
import swal from 'sweetalert2';
import { ROLE_COORDINADOR, ROLE_EVALUADO, ROLE_SUPER_ADMIN } from 'src/app/constantes/constantes';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  public sucursales: Sucursal[] = [];
  public sucursal: Sucursal;
  public usuario: Usuario;
  public rol: Rol;
  public nombreSucursal: string;
  public nombreSucursalR: string;

  constructor(
    public authService: AuthService,
    public spinner: NgxSpinnerService,
    public sucursalService: SucursalService,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.usuario = this.authService.usuarioSesion;
    this.rol = this.usuario.rol;

    if (this.rol.nombre !== ROLE_SUPER_ADMIN) {

      this.sucursales = this.authService.sucursalesSesion;
      this.sucursal = this.authService.sucursalSesion;
      if (!this.sucursal) {
        this.sucursal = this.sucursales[0];
      }
      this.nombreSucursal = this.sucursal.nombre;
      this.nombreSucursalR = (this.sucursal.nombre).length > 10 ? (this.sucursal.nombre).substr(0, 10) + '...' : (this.sucursal.nombre);
    }

  }
  obtenerSucursales() {
    if (this.rol.nombre == ROLE_EVALUADO) {
      return;
    }
    if (this.rol.nombre == ROLE_COORDINADOR) {
      this.obtenerSucursalesRolCoordinador();
    } else {
      this.obtenerSucursalesRolEvAndSucursal();
    }
  }

  obtenerSucursalesRolEvAndSucursal() {
    this.spinner.show();
    this.usuario = this.authService.usuarioSesion;
    this.sucursalService.obtenerSucursalesIdUsuario(this.usuario.idUsuario).subscribe(resp => {
      if (resp.success) {
        this.sucursales = resp.body;
        this.authService.guardarSucursales(this.sucursales);
      } else if (resp.descripcion) {
        swal.fire('Error', resp.descripcion, 'warning');
      }
      this.spinner.hide();
    }, err => {
      swal.fire('Error ', 'Servicio no disponible', 'error');
      this.spinner.hide();
    });
  }

  obtenerSucursalesRolCoordinador() {
    this.spinner.show();
    this.usuario = this.authService.usuarioSesion;
    this.sucursalService.obtenerSucursalesIdEmpresa(this.usuario.empresa.idEmpresa).subscribe(resp => {
      if (resp.success) {
        this.sucursales = resp.body;
        this.authService.guardarSucursales(this.sucursales);
      } else if (resp.descripcion) {
        swal.fire('Error', resp.descripcion, 'warning');
      }
      this.spinner.hide();
    }, err => {
      swal.fire('Error ', 'Servicio no disponible', 'error');
      this.spinner.hide();
    });
  }

  cambiarEmpresa(): void {
    this.router.navigate(['/login/login-empresas']);
  }

  logout(): void {
    this.spinner.show();
    this.authService.logout();
    this.spinner.hide();
  }
  obtenerSucursal(sucursal: Sucursal) {
    this.sucursal = sucursal;
    this.authService.guardarSucursal(this.sucursal);
    window.location.reload();
  }
}
