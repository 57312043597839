import { Injectable } from '@angular/core';

@Injectable()
export class ValidacionesService {

  private fechaInicio: Date;
  constructor(
  ) { }


  generarArreglo(modelo: any, id: any) {
    let arreglo = [
      { es_valido: true, atributo: id, mensajeError: 'campo por defecto' }
    ];
    for (const atributo in modelo) {
      if (atributo !== id) {

        if (modelo[atributo] !== null ? (typeof modelo[atributo] === 'object') : false) {
          if (Array.isArray(modelo[atributo])) {
            for (const n in modelo[atributo]) {
              for (const sub_atributo in (modelo[atributo])[n]) {
                arreglo.push({ es_valido: true, atributo: atributo + n + sub_atributo, mensajeError: 'campo por defecto' });
              }
            }
          } else {
            for (const smodel in modelo[atributo]) {
              arreglo.push({ es_valido: true, atributo: atributo + smodel, mensajeError: 'campo por defecto' });
            }
          }
        } else {
          arreglo.push({ es_valido: true, atributo: atributo, mensajeError: 'campo por defecto' });
        }
      }
    }
    
    return arreglo;
  }

  validaForm(modelo: any, arreglo : any, id: any, campos: any) {
    
    
    for (const atributo in modelo) {
      if (modelo[atributo] !== null ? (typeof modelo[atributo] === 'object') : false) {
        if (Array.isArray(modelo[atributo])) {
          for (const n in modelo[atributo]) {
            for (const sub_atributo in (modelo[atributo])[n]) {
              this.validar(modelo, arreglo, atributo, n, sub_atributo, campos);
            }
          }
        } else if ((modelo[atributo] instanceof Date)) {
          
          this.fechaInicio = atributo === 'fechaInicio' ? modelo[atributo] : this.fechaInicio;

          this.validar(modelo, arreglo, atributo, null, '', campos);
        } else {
          for (const sub_atributo in modelo[atributo]) {
            this.validar(modelo, arreglo, atributo, null, sub_atributo, campos);
          }
        }
      } else {
        this.validar(modelo, arreglo, atributo, null, '', campos);
      }



    }
    
    return !this.verificarValidacion(arreglo);

  }

  validar(modelo: any, arreglo: any, atributo: any, n: any, sub_atributo: any, campos: any) {
    for (const c in campos) {
      let atributo_entrante = n === null ?
        (sub_atributo === '' ?
          modelo[atributo] : modelo[atributo][sub_atributo]) : (modelo[atributo][n][sub_atributo]);
      if ((atributo + sub_atributo) === campos[c]) {
        if (sub_atributo === '' ? ((atributo === 'usuario') || (atributo === 'correo')) : sub_atributo === 'usuario') {
          this.validaCorreo(arreglo, (atributo + (!n ? '' : n) + sub_atributo), atributo_entrante);
        } else {
          if (atributo === 'fechaFin') {
            let valida_fecha = !this.fechaInicio ? !(!atributo_entrante) : this.fechaInicio < atributo_entrante;
            let mensaje = !atributo_entrante ? 'Fecha Fin es requerido' : 'Fecha Fin debe ser mayor a Fecha Inicio';
            this.apuntaValidacion(arreglo, atributo, valida_fecha, mensaje)

          } else {
            this.validaCaracteres(arreglo, (atributo + (!n ? '' : n) + sub_atributo), atributo_entrante);
            // this.apuntaValidacion(arreglo, (atributo + (!n ? '' : n) + sub_atributo), !(!atributo_entrante), 'Debe llenar este campo');
          }
        }
      } else {
        // this.validaCaracteres(arreglo, (atributo + (!n ? '' : n) + sub_atributo), atributo_entrante);
      }
    }
    
  }

  apuntaValidacion(arreglo: any, atributo: string, respuesta: boolean, mensaje: string) {
    arreglo.find(mod => mod.atributo === atributo).es_valido = respuesta;
    arreglo.find(mod => mod.atributo === atributo).mensajeError = (respuesta ? 'campo Correcto' : mensaje);
  }

  validaCorreo(arreglo: any, atributo: any, atributo_entrante: any) {
    if (!atributo) {
      this.apuntaValidacion(arreglo, atributo, false, 'Debe llenar este campo');
    } else {
      const mailRegex = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/g;
      this.apuntaValidacion(arreglo, atributo, ((mailRegex).test(atributo_entrante)), 'Formato de correo incorrecto');
    }
  }

  validaCaracteres(arreglo: any, atributo: any, atributo_entrante: any){
    if (!atributo_entrante){
      this.apuntaValidacion(arreglo, atributo, false, 'Debe llenar este campo');
    } else {
      const regex =/[$%&|<>#]/;
      this.apuntaValidacion(arreglo, atributo, !((regex).test(atributo_entrante)), 'No debe agregar caracteres especiales');
    }
  }

  verificarValidacion(arreglo: any) {
    for (const key in arreglo) {
      
      if (!arreglo[key].es_valido) {
        this.campoErrorFocus(key, arreglo);
        return true;
      }
    }
  }

  campoErrorFocus(key: any, arreglo: any) {
    document.getElementById(arreglo[key].atributo + 'Id').focus(

    );
    document.getElementById(arreglo[key].atributo + 'Id').addEventListener('touchend', event => {
      event.preventDefault();
    });
  }

  msjErr(atributo: string, arreglo: any) {
    return arreglo.find(mod => mod.atributo === atributo).mensajeError;
  }

  err(atributo: string, array: any) {
    return !(array.find(mod => mod.atributo === atributo).es_valido);
  }

  clnErr(atributo: string, array: any) {
    array.find(mod => mod.atributo === atributo).es_valido = true;
  }


  clnAllErr(array: any) {
    for (const key in array) {
      this.clnErr(array[key].atributo, array);
    }
  }



}
