import { Injectable } from '@angular/core';
import { URL_SERVICIOS } from 'src/app/config/config';
import { ReporteUtilService } from './reporte.util.service';

@Injectable()
export class ReporteIndService {

  public content: any[] = [];

  constructor(
    public reporteUtilService: ReporteUtilService
  ) { }

  crearReporteGuiaI(reporte: any) {

    this.creaEnzabezado(reporte);
    this.crearApartadoIndGuiaI(reporte);
    this.crearCategoriasGuiaI(reporte);
    return this.obtenerPdfInd(reporte);
  }

  crearApartadoIndGuiaI(reporte: any) {
    let res = [];
    res.push([
      [
        {
          // style: 'tableNecAccion',
          table: {
            widths: [150, '*',],
            body: this.contruyeReporteIndGuiaI(reporte),
          },
          layout: 'noBorders'
        }
      ],
      { text: reporte.reqAtencion ? '\nEl trabajador requiere de una valoración clínica.' : '\nEl trabajador  no requiere de una valoración clínica.', bold: true, fontSize: 13, alignment: 'justify' }
    ]);

    this.content.push({
      style: 'tableNecAccion',
      table: {
        widths: [150, '*'],
        body: res
      },
      layout: 'noBorders'
    });

    this.content.push({
      alignment: 'center',
      text: 'Respuestas dadas por el evaluado', fontSize: 18, bold: true,
      style: 'header',
    });
  }

  contruyeReporteIndGuiaI(reporte: any) {

    let categorias: any[] = [];

    categorias.push([
      { text: `Requiere Atención clínica`, fontSize: 9, alignment: 'center' },
    ]);

    categorias.push([
      { text: `${reporte.reqAtencion ? 'SÍ' : 'NO'}`, fontSize: 14, bold: true, alignment: 'center', fillColor: this.obtenerColorValoracion(reporte.reqAtencion) },
    ]);

    return categorias;
  }

  crearCategoriasGuiaI(reporte: any) {

    let categorias: any[] = [];
    reporte.categorias.forEach((element, index) => {
      categorias.push([
        [
          {
            style: 'tableNecAccion',
            table: {
              widths: [500],
              body: [
                [
                  { text: `${index + 1}.- ${element.nombre}`, color: '#276294', fontSize: 19, bold: true, border: [false, false, false, false] },
                ]
              ]
            },
          },
          {
            style: 'tableNecAccion',
            table: {
              widths: [500, '*'],
              body: this.contruyePreguntasResp(element.preguntas),
              layout: {
                defaultBorder: false,
              }
            },
          }
        ]
      ]);
    });

    this.content.push({
      style: 'tableNecAccion',
      table: {
        widths: [550],
        body: categorias,
      },
      layout: 'noBorders'
    });

  }

  contruyePreguntasResp(preguntas: any = []) {
    let preguntasRep: any[] = [];

    preguntas.forEach(element => {
      preguntasRep.push([

        { text: `${element.nombre}`, fontSize: 11, border: [false, false, false, false] },
        { text: `${element.tipoPonderacion}`, bold: true, fontSize: 11, border: [false, false, false, false] }

      ]);
    });
    return preguntasRep;
  }

  crearReporte(reporte: any) {

    this.creaEnzabezado(reporte);
    this.crearApartadoIndRes(reporte);
    this.crearCategorias(reporte);
    this.crearCategoriasPreguntas(reporte);
    return this.obtenerPdfInd(reporte);
  }

  creaEnzabezado(reporte: any) {

    this.content = []

    let cuestionatio = reporte.cuestionario ? reporte.cuestionario.nombre : '';
    this.content.push({
      alignment: 'center',
      text: `Cuestionario: ${cuestionatio}\n`,
      style: 'headerInd',
    });
    this.content.push({
      alignment: 'rigth',
      text: `Datos del Evaluado`,
      style: 'headerInd',
    });

    this.content.push({
      alignment: 'justify',
      columns: [
        {
          text: [
            `Nombre: `,
            { text: `${reporte.empleado.usuario.nombre} ${reporte.empleado.usuario.apePaterno} ${reporte.empleado.usuario.apeMaterno}\n`, bold: false, style: 'subheader' }
          ],
          style: 'subheaderInd'
        },
        {
          text: [
            `Edad: `,
            { text: reporte.empleado.usuario.edad === 0 ? ' - \n' : ` ${reporte.empleado.usuario.edad} años\n`, bold: false, style: 'subheader' }
          ],
          style: 'subheaderInd'
        },
      ]
    });

    this.content.push({
      alignment: 'justify',
      columns: [
        {
          text: [
            `Departamento: `,
            { text: `${reporte.empleado.puesto.departamento.nombre}\n`, bold: false, style: 'subheader' }
          ],
          style: 'subheaderInd'
        },
        {
          text: [
            `Puesto: `,
            { text: ` ${reporte.empleado.puesto.nombre} \n`, bold: false, style: 'subheader', foreground: 'red' }
          ],
          style: 'subheaderInd'
        },
      ]
    });

    this.content.push({
      alignment: 'justify',
      columns: [
        {
          text: [
            `Fecha de aplicación : `,
            { text: `${reporte.fechaContestado}\n\n\n`, bold: false, style: 'subheader' }
          ],
          style: 'subheaderInd'
        }
      ]
    });

  }

  crearApartadoIndRes(reporte: any) {
    let res = [];
    res.push([
      [
        {
          // style: 'tableNecAccion',
          table: {
            widths: [100, '*', '*'],
            body: this.contruyeReporteInd(reporte),
          },
          layout: 'noBorders'
        }
      ],
      { text: reporte.necesidadAccion, fontSize: 12, alignment: 'justify' }
    ]);

    this.content.push({
      style: 'tableNecAccion',
      table: {
        widths: [130, '*'],
        body: res
      },
      layout: 'noBorders'
    });
  }


  contruyeReporteInd(reporte: any) {

    let categorias: any[] = [];

    categorias.push([
      { text: `NIVEL DE RIESGO`, fontSize: 9, alignment: 'center' },
    ]);

    categorias.push([
      { text: `${reporte.nivelRiesgo}`, fontSize: 14, bold: true, alignment: 'center', fillColor: this.obtenerColor(reporte.nivelRiesgo) },
    ]);

    categorias.push([
      { text: `PUNTOS`, fontSize: 9, alignment: 'center' },
    ]);

    categorias.push([
      { text: `${reporte.puntos}`, bold: true, fontSize: 14, alignment: 'center' },
    ]);

    return categorias;
  }

  crearCategorias(reporte: any) {
    let categorias: any[] = [];
    reporte.categorias.forEach(element => {
      categorias.push([
        [
          {
            style: 'tableNecAccion',
            table: {
              widths: [200, '*', '*'],
              body: [
                [
                  { text: 'Categoría', fontSize: 7, border: [false, false, false, false] },
                  { text: 'Puntos', fontSize: 7, border: [false, false, false, false] },
                  { text: 'Nivel de Riesgo', fontSize: 7, border: [false, false, false, false] }
                ],
                [
                  { text: element.nombre, fontSize: 11, bold: true, border: [false, false, false, false] },
                  { text: element.puntos, fontSize: 11, bold: true, border: [false, false, false, false] },
                  { text: element.nivelRiesgo, bold: true, fillColor: this.obtenerColor(element.nivelRiesgo), fontSize: 11, border: [false, false, false, false] }
                ]
              ]
            },
          },
          {
            style: 'tableNecAccion',
            table: {
              widths: [200, '*', '*'],
              body: this.contruyeDominios(element.dominios),
              layout: {
                defaultBorder: false,
              }
            },
          }
        ]
      ]);
    });
    this.content.push({
      style: 'tableNecAccion',
      table: {
        widths: [550],
        body: categorias,
      },
      layout: 'noBorders'
    });

    this.content.push({
      alignment: 'center',
      text: 'DETALLE DE LOS RESULTADOS POR CATEGORÍA Y DOMINIO', fontSize: 18, bold: true,
      style: 'header',
    });
  }

  crearCategoriasPreguntas(reporte: any) {

    let categoriasDetalle: any[] = [];

    reporte.categorias.forEach(element => {
      categoriasDetalle.push([
        [
          {
            style: 'tableNecAccion',
            table: {
              widths: [200, '*', '*'],
              body: [
                [
                  { text: 'Categoria', fontSize: 7, border: [false, false, false, false] },
                  { text: 'Puntos', fontSize: 7, border: [false, false, false, false] },
                  { text: 'Nivel de Riesgo', fontSize: 7, border: [false, false, false, false] }
                ],
                [
                  { text: element.nombre, fontSize: 11, bold: true, border: [false, false, false, false] },
                  { text: element.puntos, fontSize: 11, bold: true, border: [false, false, false, false] },
                  { text: element.nivelRiesgo, bold: true, fillColor: this.obtenerColor(element.nivelRiesgo), fontSize: 11, border: [false, false, false, false] }
                ]
              ]
            },
          },
          {
            style: 'tableNecAccion',
            table: {
              widths: [200, '*', '*'],
              body: this.contruyeDominiosPre(element.dominios),
              layout: {
                defaultBorder: false,
              }
            },
          }
        ]
      ]);
    });

    this.content.push({
      style: 'tableNecAccion',
      table: {
        widths: [550],
        body: categoriasDetalle,
      },
      layout: 'noBorders'
    });
  }

  contruyeDominiosPre(dominios: any = []) {
    let categorias: any[] = [];
    dominios.forEach(element => {
      categorias.push([
        { text: `Dominio`, fontSize: 7, border: [false, false, false, false] },
        { text: `Puntos`, fontSize: 7, border: [false, false, false, false] },
        { text: `Nivel de Riesgo`, fontSize: 7, border: [false, false, false, false] }
      ]);
      categorias.push([
        { text: `${element.nombre}`, bold: true, fontSize: 9, border: [false, false, false, false] },
        { text: `${element.puntos}`, bold: true, fontSize: 9, border: [false, false, false, false] },
        { text: `${element.nivelRiesgo}\n\n`, bold: true, fontSize: 9, fillColor: this.obtenerColor(element.nivelRiesgo), border: [false, false, false, false] },
      ]);

      categorias.push([
        { text: `PREGUNTAS`, colSpan: 2, bold: false, fontSize: 10, border: [false, false, false, false] },
        { text: ``, fontSize: 10, border: [false, false, false, false] },
        { text: `RESPUESTA`, fontSize: 10, bold: false, border: [false, false, false, false] }
      ]);

      element.preguntas.forEach(element => {
        categorias.push([
          { text: `* ${element.nombre}`, colSpan: 2, bold: false, border: [false, false, false, false] },
          { text: ``, fontSize: 7, bold: false, border: [false, false, false, false] },
          { text: `${element.tipoPonderacion}`, bold: false, fontSize: 9, border: [false, false, false, false] }
        ]);
      });
      categorias.push([

        { text: 'NECESIDAD DE ACCIÓN', fontSize: 12, colSpan: 3, bold: true, fillColor: this.obtenerColor(element.nivelRiesgo), border: [false, false, false, false] }
      ]);
      categorias.push([

        { text: element.necesidadAccion, fontSize: 11, colSpan: 3, italics: true, bold: true, border: [false, false, false, true] }
      ]);


    });
    return categorias;
  }

  contruyePreguntas(preguntas: any = []) {
    let categorias: any[] = [];
    preguntas.forEach(pregunta => {
      categorias.push([

        { text: `${pregunta.nombre}`, border: [false, false, false, false] },
        { text: `${pregunta.tipoPonderacion}`, border: [false, false, false, false] }

      ]);
    });
    return categorias;
  }

  contruyeDominios(dominios: any = []) {
    let categorias: any[] = [];
    categorias.push([
      { text: `Dominio`, fontSize: 7, border: [false, false, false, false] },
      { text: `Puntos`, fontSize: 7, border: [false, false, false, false] },
      { text: `Nivel de Riesgo`, fontSize: 7, border: [false, false, false, false] }
    ]);
    dominios.forEach(element => {
      categorias.push([

        { text: `    ${element.nombre}`, fontSize: 9, border: [false, false, false, false] },
        { text: `    ${element.puntos}`, fontSize: 9, border: [false, false, false, false] },
        { text: `    ${element.nivelRiesgo}`, fontSize: 9, fillColor: this.obtenerColor(element.nivelRiesgo), border: [false, false, false, false] },

      ]);
    });

    categorias.push([

      { text: `    `, border: [false, false, false, true] },
      { text: `  `, border: [false, false, false, true] },
      { text: ` `, border: [false, false, false, true] }

    ]);
    return categorias;
  }

  obtenerPdfInd(reporte: any) {
    let content = this.content
    var docDefinition = {
      footer: function (currentPage, pageCount) {
        return [
          {
            text: 'Pagína ' + currentPage.toString(),
            alignment: 'right',
            margin: [20, 0]
          },
        ]
      },
      header: function (currentPage, pageCount, pageSize) {
        return [
          {
            columns: [
              {
                image: 'snow',
                width: 60,
                margin: [20, 10, 0, 0],
              },
              {
                text: [
                  { text: `${reporte.empresa.razonSocial}\n`, alignment: 'center', bold: false, style: 'headerEmpresa' },
                  {
                    text: [
                      { text: `Centro Trabajo: `, fontSize: 10, bold: false, alignment: 'center' },
                      { text: `${reporte.sucursal.nombre}`, fontSize: 14, bold: true, alignment: 'center' }
                    ]
                  }
                ],
                margin: [40, 20, 0, 0],
              },

            ]
          },
          {
            canvas: [{
              type: 'line', x1: 10, y1: 10, x2: 595 - 10, y2: 10, lineWidth: 0.5
            }]
          }
        ]
      },
      pageMargins: [20, 80, 20, 30],
      content,
      images: {
        mySuperImage: 'data:image/jpeg;base64,...content...',
        snow: URL_SERVICIOS + '/api/empresas/uploads/img/' + reporte.empresa.logo,
      },

      styles: {

        subheader: {
          fontSize: 13,
          margin: [0, 0, 0, 5],
        },
        tableNecAccion: {
          margin: [0, 5, 0, 15],
          fontSize: 9,
        },
        tablePregu: {
          fontSize: 8,
        },
        headerInd: {
          fontSize: 18,
          bold: true,
          margin: [0, 0, 0, 7],
        },
        headerEmpresa: {
          fontSize: 15,
          bold: true,
        },
        headerCt: {
          fontSize: 13,
          bold: true,
          color: '#398bf7'
        },
        subheaderInd: {
          fontSize: 13,
          bold: true,
          margin: [0, 0, 0, 5],
        },
      }
    };
    return docDefinition;
  }

  obtenerColor(nivelRiesgo: string) {

    let clase;
    switch (nivelRiesgo) {
      case 'MUY ALTO':
        clase = '#ff3500';
        break;
      case 'ALTO':
        clase = '#ff8000';
        break;
      case 'MEDIO':
        clase = '#fdf731';
        break;
      case 'BAJO':
        clase = '#16a53e';
        break;
      case 'NULO':
        clase = '#00bff3';
        break;
      default: clase = '';
        break;
    }

    return clase;
  }

  obtenerColorValoracion(necesitaValoracion: boolean) {

    let clase;
    switch (necesitaValoracion) {
      case true:
        clase = '#ff3500';
        break;
      case false:
        clase = '#00bff3';
        break;
      default: clase = '';
        break;
    }

    return clase;
  }

}