import { Injectable } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Injectable()
export class ModalDepartamentoService {
  public notificacion = new EventEmitter<any>();
  public modalDepartamento = false;

  constructor() { }

  mostrarModalDepartamento() {
    this.modalDepartamento = true;
  }

  ocultarModalDepartamento() {
    this.modalDepartamento = false;
  }

}
