import { Injectable } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Injectable()
export class ModalEmpleadoService {
  public notificacion = new EventEmitter<any>();
  public modalEmpleado = false;

  constructor() { }

  mostrarModalEmpleado() {
    this.modalEmpleado = true;
  }

  ocultarModalEmpleado() {
    this.modalEmpleado = false;
  }

}
