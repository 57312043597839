import { Injectable } from '@angular/core';
import { URL_SERVICIOS } from '../../config/config';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Puesto } from 'src/app/models/puesto.model';

@Injectable()
export class PuestoService {

  constructor(
    public http: HttpClient
  ) { }

  obtenerPuestosPorDepartamento(httpParams: HttpParams) {
    let url = `${URL_SERVICIOS}/api/puestos`;
    return this.http.get<any>(url,{ params: httpParams });
  }

  crearPuesto(puesto: Puesto) {
    let url = `${URL_SERVICIOS}/api/puestos`;
    return this.http.post<any>(url, puesto);
  }

  actualizar(puesto: Puesto) {
    let url = `${URL_SERVICIOS}/api/puestos/${puesto.idPuesto}`;
    return this.http.put<any>(url, puesto);
  }



}