import { Injectable } from '@angular/core';
import { URL_SERVICIOS } from '../../config/config';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable()
export class ReporteService {

  constructor(
    public http: HttpClient
  ) { }


  obtenerReportesGuias(httpParams: HttpParams) {
    let url = `${URL_SERVICIOS}/api/reportes/guias`;
    return this.http.get<any>(url, { params: httpParams });
  }


  obtenerReporteGuiaI(httpParams: HttpParams) {
    let url = `${URL_SERVICIOS}/api/reportes/guia-uno`;
    return this.http.get<any>(url, { params: httpParams });
  }

  obtenerReporteCategoriasExcel(httpParams: HttpParams) {
    let url = `${URL_SERVICIOS}/api/reportes/excel/categorias`;
    return this.http.get<any>(url, { params: httpParams });
  }

  obtenerReporteIndividual(httpParams: HttpParams) {
    let url = `${URL_SERVICIOS}/api/reportes/ind`;
    return this.http.get<any>(url, { params: httpParams });
  }

  obtenerReporteIndividualGuiaI(httpParams: HttpParams) {
    let url = `${URL_SERVICIOS}/api/reportes/ind/guia-uno`;
    return this.http.get<any>(url, { params: httpParams });
  }

  obtenerReporteZip(json:any) {
    let url = `${URL_SERVICIOS}/api/reporte/zip/periodo/${json[0].idPeriodo}/cuestionario/${json[0].idEvaluacion}/contestado`;
    let params = new HttpParams();
    if(json[0].idDepto){
      params = params.append("idDepto", json[0].idDepto);
    }
    if(json[0].idPuesto != null){
      params = params.append("idPuesto", json[0].idPuesto);
    }
    return this.http.get<any>(url  , { params: params , observe: 'response',responseType: 'blob' as 'json' });
  }


  obtenerReportePDF(json:any) {
    console.log('joe')
    let url = `${URL_SERVICIOS}/api/reporte/pdf/periodo/${json.idPeriodo}/cuestionario/${json.idEvaluacion}/empleado/${json.idEmpleado}/contestado`;
    return this.http.get<any>(url , {observe: 'response',responseType: 'blob' as 'json'});
  }



}