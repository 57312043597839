import { Injectable } from '@angular/core';
import { URL_SERVICIOS } from '../../config/config';

@Injectable()
export class UtilService {


  constructor(
  ) { }

  raplaceSaltoDeLineaUtil(cadenaIn: string) {
    return cadenaIn.replace(/\n/g, '<br>');
  }

  obtenerUrl(idFoto: number, nombreFoto: string) {
    return URL_SERVICIOS + '/api/archivo/img/' + idFoto + '/' + nombreFoto;
  }

  obtenerUrlSinId(nombreFoto: string) {
    return URL_SERVICIOS + '/api/archivo/img/' + nombreFoto;
  }

  obtenerUrlEmpresa(idFoto: number, nombreFoto: string) {
    return URL_SERVICIOS + '/api/archivo/img/empresa/' + idFoto + '/' + nombreFoto;
  }

  obtenerUrlEmpresaSinID(nombreFoto: string) {
    return URL_SERVICIOS + '/api/archivo/img/empresa/' + nombreFoto;
  }
  obtenerNombreImagenReal(nombreFoto: string) {
    if (nombreFoto == null) {
      return '';
    }
    const lastIndexOf = nombreFoto.lastIndexOf('$');
    if (lastIndexOf === -1) {
      return '';
    }
    return nombreFoto.substring(lastIndexOf + 1);
  }

  replaceMensaje(message: string, replace: string) {
    if (message === null) {
      return null;
    }
    return message.replace('{}', replace);
  }

  decode(s) {
    for (var a, b, i = -1, l = (s = s.split('')).length, o = String.fromCharCode, c = 'charCodeAt'; ++i < l;
      ((a = s[i][c](0)) & 0x80) &&
      (s[i] = (a & 0xfc) == 0xc0 && ((b = s[i + 1][c](0)) & 0xc0) == 0x80 ?
        o(((a & 0x03) << 6) + (b & 0x3f)) : o(128), s[++i] = '')
    );
    return s.join('');
  }

  obtenerUrlLogo(logo: string){
    return URL_SERVICIOS + '/api/empresas/uploads/img/' + logo;
  }


  
}