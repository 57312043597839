import { Injectable } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Injectable()
export class ModalPuestoService {
  public notificacion = new EventEmitter<any>();
  public modalPuesto= false;

  constructor() { }

  mostrarModalPuesto() {
    this.modalPuesto = true;
  }

  ocultarModalPuesto() {
    this.modalPuesto = false;
  }

}
