import { Injectable } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Injectable()
export class ModalEmpresaService {
  public notificacion = new EventEmitter<any>();
  public modalEmpresa = false;

  constructor() { }

  mostrarModalEmpresa() {
    this.modalEmpresa = true;
  }

  ocultarModalEmpresa() {
    this.modalEmpresa = false;
  }

}
