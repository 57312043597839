import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Usuario } from '../../models/usuario.model';
import { URL_SERVICIOS } from 'src/app/config/config';
import { UtilService } from '../utils/util.service';
import { Menu } from 'src/app/models/menu.model';
import { Sucursal } from 'src/app/models/sucursal.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  usuario: Usuario;
  token: string;
  tokenRefresh: string;
  menus: Menu[] = [];
  sucursales: Sucursal[] = [];
  sucursal: Sucursal;

  constructor(
    public http: HttpClient,
    public utilService: UtilService
  ) { }

  public get usuarioSesion(): Usuario {

    if (localStorage.getItem('usuario') != null) {
      this.usuario = JSON.parse(localStorage.getItem('usuario')) as Usuario;
      return this.usuario;
    }
  }
  public get tokenSesion(): string {
    if (this.token != null) {
      return this.token;
    } else if (this.token == null && localStorage.getItem('token-admin') != null) {
      this.token = localStorage.getItem('token-admin');
      return this.token;
    }
    return null;
  }
  public get tokenRefreshSession(): string {
    if (this.tokenRefresh != null) {
      return this.tokenRefresh;
    } else if (this.tokenRefresh == null && localStorage.getItem('refresh_token') != null) {
      this.tokenRefresh = localStorage.getItem('refresh_token');
      return this.tokenRefresh;
    }
    return null;
  }

  public get menuSesion(): Menu[] {
    if (this.menus != null && this.menus.length > 0) {
      return this.menus;
    } else if (this.menus == null || this.menus.length === 0 && localStorage.getItem('menus') != null) {
      this.menus = JSON.parse(localStorage.getItem('menus'));
      return this.menus;
    }
    return null;
  }

  public get sucursalesSesion(): Sucursal[] {
    if (this.sucursales != null && this.sucursales.length > 0) {
      return this.sucursales;
    } else if (this.sucursales == null || this.sucursales.length === 0 && localStorage.getItem('sucursales') != null) {
      this.sucursales = JSON.parse(localStorage.getItem('sucursales'));
      return this.sucursales;
    }
    return null;
  }

  public get sucursalSesion(): Sucursal {
    if (this.sucursal != null) {
      return this.sucursal;
    } else if (this.sucursal == null && localStorage.getItem('sucursal') != null) {
      this.sucursal = JSON.parse(localStorage.getItem('sucursal')) as Sucursal;
      return this.sucursal;
    }
    return null;
  }

  login(usuario: Usuario) {
    const credenciales = btoa('angularapp' + ':' + 'norma035');

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: 'Basic ' + credenciales
    });
    const params = new URLSearchParams();
    params.set('grant_type', 'password');
    params.set('username', usuario.usuario);
    params.set('password', usuario.password);

    let url = `${URL_SERVICIOS}/oauth/token`;
    return this.http.post<any>(url, params.toString(), { headers: httpHeaders });

  }

  guardarToken(accessToken: string): void {

    this.token = accessToken;
    localStorage.setItem('token-admin', accessToken);

  }

  guardarTokenRefresh(refreshToken: any) {
    this.tokenRefresh = refreshToken;
    localStorage.setItem('refresh_token', refreshToken);
  }

  guardarUsuario(accessToken: string): void {
    this.guardarToken(accessToken);
    const payload = this.obtenerDatosToken(accessToken);
    this.usuario = payload.usuario;
    localStorage.setItem('usuario', JSON.stringify(payload.usuario));
  }

  obtenerDatosToken(accessToken: string): any {
    if (accessToken != null) {
      return JSON.parse(this.utilService.decode(atob(accessToken.split('.')[1])));
    }
    return null;
  }

  isAuthenticated(): boolean {
    const payload = this.tokenSesion;
    if (payload != null && payload.length > 0) {
      return true;
    }
    return false;
  }


  logout(): void {
    this.token = null;
    this.usuario = null;
    localStorage.removeItem('token-admin');
    localStorage.removeItem('usuario');
    localStorage.clear();
    window.location.reload();
  }

  guardarMenus(menuResp: Menu[]): void {
    this.menus = menuResp;
    localStorage.setItem('menus', JSON.stringify(menuResp));
  }

  guardarSucursales(sucursales: Sucursal[]): void {
    this.sucursales = sucursales;
    localStorage.setItem('sucursales', JSON.stringify(sucursales));
  }

  guardarSucursal(sucursal: Sucursal): void {
    this.sucursal = sucursal;
    localStorage.setItem('sucursal', JSON.stringify(sucursal));
  }

  actualizarUsuario(user: Usuario) {

    this.usuario = user;
    localStorage.setItem('usuario', JSON.stringify(user));

  }

  hasRole(role: string): boolean {
    if (this.usuario.rol.nombre === role) {
      return true;
    }
    return false;
  }

}
