import { Injectable } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Injectable()
export class ModalSucursalService {
  public notificacion = new EventEmitter<any>();
  public modalSucursal = false;

  constructor() { }

  mostrarModalSucursal() {
    this.modalSucursal = true;
  }

  ocultarModalSucursal() {
    this.modalSucursal = false;
  }

}
