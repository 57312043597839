import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ROLE_COORDINADOR } from 'src/app/constantes/constantes';
import { AuthService } from 'src/app/services/service.index';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService,
    private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    if (this.authService.isAuthenticated()) {
      if (this.isTokenExpirado()) {
        this.authService.logout();
        this.router.navigate(['/login']);
        return false;
      }
      if (this.authService.usuarioSesion.rol.nombre === ROLE_COORDINADOR) {
        if(!this.authService.usuarioSesion.empresa || !this.authService.usuarioSesion.empresa.razonSocial){
          this.router.navigate(['/login/login-empresas']);
          return true;
        }
        
        return true;
      }
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }

  }

  isTokenExpirado(): boolean {
    const token = this.authService.tokenSesion;
    const payload = this.authService.obtenerDatosToken(token);
    const now = new Date().getTime() / 1000;
    if (payload.exp < now) {
      return true;
    }
    return false;
  }
}
